function decorateClasses(className, key, value) {
  const elements = document.getElementsByClassName(className);
  for (const element of elements) {
    element[key] = value
  }
}

function decorateTags(tagName, key, value) {
  const elements = document.getElementsByTagName(tagName);
  for (const element of elements) {
    if(element.hasAttribute(tagName) === false) {
      element.setAttribute(key, value)
    }
  }
}

function removeAttributeFromTag(tagName, key) {
  const elements = document.getElementsByTagName(tagName);
  for (const element of elements) {
    if(element.hasAttribute(tagName)) {
      element.removeAttribute(key)
    }
  }
}


function removeAttributeFromClass(tagName, key) {
  const elements = document.getElementsByClassName(tagName);
  for (const element of elements) {
    element.removeAttribute(key)
  }
}

function upgradeFooter() {
  const elements = document.getElementsByTagName('a');

  for (const element of elements) {
    const href = element.getAttribute('href');
    if (href) continue;
    const innerHTML = element.innerHTML;
    const parentElement = element.parentElement
    const newElement = document.createElement('span');

    newElement.setAttribute('role', 'link')
    newElement.setAttribute('tabindex', 0)
    newElement.onclick = () => element.click();
    newElement.innerHTML = innerHTML.trim();
    newElement.classList = 'bpce-fix-link-hover';
    newElement.style.color = '#333';
    newElement.style.frontWeight = '400';
    newElement.style.fontSize = '13px';
    newElement.style.lineHeight = '18px';

    newElement.onkeydown = (event) => {
      if(event.code === 'Space' || event.code === 'Enter') element.click()
      return
    }
    parentElement.replaceChild(newElement, element);
  }
}

function upgradeInfobox() {
  const elements = document.getElementsByClassName('bpce-infobox-title');
  for (const element of elements) {
    if(element.tagName === 'H3') continue
    const innerHTML = element.innerHTML;
    const parentElement = element.parentElement
    const newElement = document.createElement('h3');
    newElement.classList = element.classList;
    newElement.innerHTML = innerHTML;
    newElement.style.font = '700 1rem/1.375rem Ubuntu,Arial,sans-serif';
    newElement.style.letterSpacing = '-.2px';
    newElement.style.marginLeft = '8px';
    parentElement.replaceChild(newElement, element);
  }
}

function fixDialog() {
  const elements = document.getElementsByTagName('dialog');
  for (const element of elements) {
    const titleElement = element.querySelector('h1')
    if(!titleElement) continue;
    element.setAttribute('aria-labelledby', titleElement.id);
    if(titleElement.hasAttribute('tabindex') === false) {
      titleElement.setAttribute('tabindex', '0');
      titleElement.focus()
    }

    // element.click()
  }
}

function fixFabButton() {
    const element = document.getElementById('fabBtn');
    if(element) {
      element.title = 'Besoin d\'aide ?';
    }

    const elements = document.getElementsByClassName('bpce-fab');
    for(const element of elements) {
      if(element.tagName === 'DIV') continue
      const parentElement = element.parentElement
      const newElement = document.createElement('div');
      newElement.classList = element.classList;
      newElement.innerHTML = element.innerHTML;
      newElement.style.position = 'fixed'
      newElement.style.bottom = '16px'
      newElement.style.right = '16px'
      newElement.style.zIndex = 999
      parentElement.replaceChild(newElement, element);
    }
}


function fixEmailField() {
  const elements = document.getElementsByTagName('ui-input-email');
  for(const element of elements) {
    const inputElement = element.querySelector('input')
    inputElement.setAttribute('aria-describedby', 'description-erreur-email description-champs-email')
    inputElement.setAttribute('autocomplete', 'email')
  }

  for(const element of elements) {
    const invalid = element.getAttribute('aria-invalid')
    const inputElement = element.querySelector('input')
    if(invalid !== null && inputElement && inputElement.hasAttribute('aria-invalid')) {
      inputElement.setAttribute('aria-invalid', String(invalid))
      element.removeAttribute('aria-invalid')
    }
  }
}

function fixAmountField() {
  const elements = document.getElementsByTagName('ui-input-amount');
  for(const element of elements) {
    const invalid = element.getAttribute('aria-invalid')
    const inputElement = element.querySelector('input')
    if(invalid !== null && inputElement && inputElement.hasAttribute('aria-invalid')) {
      inputElement.setAttribute('aria-invalid', String(invalid))
      element.removeAttribute('aria-invalid')
    }
  }
}


function fixCloseButton() {
  const elements = document.getElementsByTagName('ui-header');
  for(const element of elements) {
    const closeTitle = element.getAttribute('close-title')
    const buttonClosesElements = element.getElementsByClassName('bpce-header-close')
    for(const buttonElement of buttonClosesElements) {
      if(closeTitle !== null && !buttonElement.hasAttribute('title')) {
        // buttonElement.setAttribute('id', 'bpce-header-close')
        buttonElement.setAttribute('title', closeTitle)
        element.removeAttribute('close-title')
      }
    }

    const backTitle = element.getAttribute('back-title')
    const buttonsBackElements = element.getElementsByClassName('bpce-header-left-custom')
    for(const buttonElement of buttonsBackElements) {
      if(backTitle !== null) {
        buttonElement.setAttribute('title', backTitle)
        element.removeAttribute('back-title')
      }
    }
  }
}


function fixRadioButton() {
  const elements = document.getElementsByClassName('bpce-radio-label');
  for(const element of elements) {
    if(element.innerHTML.includes('span')) continue
    const innerHtml = element.innerHTML;
    element.innerHTML = '';
    const subElement = document.createElement('span');
    subElement.innerHTML = innerHtml
    subElement.classList = 'bpce-visually-hidden'
    element.appendChild(subElement)
  }
}


function fixStepper() {
  const elements = document.getElementsByClassName('bpce-stepper');
  for(const element of elements) {
    if(element.tagName === 'OL') continue;
    const innerHtml = element.innerHTML;
    const newElement = document.createElement('ol');
    newElement.innerHTML = innerHtml
    newElement.classList = 'bpce-stepper';
    newElement.style.display = 'flex';
    newElement.style.alignItems = 'flex-end'
    newElement.style.padding = '0 0 8px'
    newElement.style.listStyle = 'none'
    element.parentElement.replaceChild(newElement, element)
  }
}


function fixAriaLabel() {
  const elements = document.getElementsByClassName('bpce-input-v2-icon');
  for(const element of elements) {
    const value = element.getAttribute('arialabel')
    if(element.hasAttribute('aria-label') === false) {
      element.setAttribute('aria-label', value)
      element.removeAttribute('arialabel')
    }
  }
}


function fixAriaDescribedby() {
  const elements = document.getElementsByClassName('to-bpce-fix');
  for(const element of elements) {
    const value = element.getAttribute('aria-describedby')
    const subElements = element.getElementsByTagName('input')
    for(const subElement of subElements) {
      if(subElement.hasAttribute('aria-describedby') === false) {
        subElement.setAttribute('aria-describedby', value)
        element.removeAttribute('aria-describedby')
      }
    }
  }
}



function fixAriaLabelBoutonAction() {
  const elements = document.getElementsByTagName('button');
  for(const element of elements) {
    const value = element.getAttribute('aria-label')
    if(value === 'Bouton d\'action') {
      element.removeAttribute('aria-label')
    }
  }
}



function fixUiErrors() {
  const elements = document.getElementsByTagName('ui-error');
  for(const element of elements) {
    const role = element.getAttribute('role')
    const ariaAtomic = element.getAttribute('aria-atomic')

    const paragraphs = element.getElementsByTagName('p');
    for(const paragraph of paragraphs) {
      if(paragraph.hasAttribute('role') === false) {
        paragraph.setAttribute('role', role)
      }
      if(paragraph.hasAttribute('aria-atomic') === false) {
        paragraph.setAttribute('aria-atomic', ariaAtomic)
      }
    }
  }
}


function bpceFix() {
  decorateTags('input', 'aria-required', 'true');
  removeAttributeFromTag('input', 'aria-label')
  removeAttributeFromClass('bpce-toggle-input', 'aria-required')
  removeAttributeFromClass('bpce-checkbox-input', 'aria-required')
  removeAttributeFromClass('bpce-input-v2-label-float', 'aria-hidden')
  removeAttributeFromClass('bpce-input-v2-label', 'aria-hidden')
  // decorateClasses('bpce-input-v2-field', 'autocomplete', 'email');
  decorateClasses('ng-valid', 'aria-invalid', 'false');
  decorateClasses('ng-invalid', 'aria-invalid', 'true');
  decorateTags('svg', 'aria-hidden', 'true');
  decorateTags('svg', 'focusable', 'false');
  decorateTags('footer', 'role', 'contentinfo');
  // decorateTags('dialog', 'tabindex', '-1');
  decorateTags('dialog', 'aria-modal', 'true');
  // removeAttributeFromTag('button', 'aria-label')
  fixCloseButton()
  upgradeFooter();
  upgradeInfobox();
  fixDialog();
  fixRadioButton();
  fixEmailField();
  fixAmountField();
  fixFabButton();
  fixStepper();
  fixAriaLabel();
  fixAriaLabelBoutonAction();
  fixAriaDescribedby()
  fixUiErrors()
  // fixHeight();
  // fixCloseButton();
}

setInterval(bpceFix, 500)

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
})

window.onload = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
